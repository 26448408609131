import $ from 'jquery'

class WordCounter

  run: ->
    console.log("WordCounter#run")
    area = $('#rating_comment').first()
    countDown = $('.count-down')
    area.keyup( ->

      count = area.val().replace(/^[\s,.;]+/, "").replace(/[\s,.;]+$/, "").split(/[\s,.;]+/).length
      todo = 600 - count
      if todo > 0
        countDown.html("Noch " + todo + " Wörter und Schueleraustausch.net spendet 1€ an SOS-Kinderdörfer.")
      else
        countDown.html("Spendenziel erreicht...")
    )

initWordCounter = ->
  if $('textarea#rating_comment').length > 0
    wc = new WordCounter()
    wc.run()


$(document).on('turbolinks:load', initWordCounter)
