import $ from 'jquery'
prepareTracking = ->
  initRatingCreated()
  initEventTracking()

# reload tracks again!
# change piwik goal to ignore duplicates if you don't want this
initRatingCreated = ->
  if $('.trackgoal').length > 0
    ele = $('.trackgoal')
    gId = ele.data('goal-id')
    comment = ele.data('comment')
    if PiwikAnalytics?
      PiwikAnalytics.trackGoal(gId)
      PiwikAnalytics.trackEvent(railsController(), 'create', comment)

initEventTracking = ->
  # this has to stay idempotent (https://en.wikipedia.org/wiki/Idempotence)
  # i.e.: register event handlers once, don't register _new_ handlers when called again.
  # otherwise, reloading the page will result in all tracklinks producing dupes!
  $els = $('.analytics-event')
  if $els.length > 0
    $els.each ->
      $el = $(@)
      trackEvent(@)

trackEvent = (el) ->
  tag = el.tagName
  $el = $(el)
  switch tag
    when 'A' then trackLink($el)
    when 'SELECT' then trackSelect($el)
    when 'INPUT' then trackInput($el)
    when 'BUTTON' then trackButton($el)
    else trackUnknown($el)

trackUnknown = ($el) ->
  console.log('unknown tracking element', el)

trackInput = ($el) ->
  tracker = ->
    if PiwikAnalytics?
      PiwikAnalytics.trackEvent(railsController(), 'press', $el.attr('value'))
  $el.off 'click', tracker
  $el.on 'click', tracker

trackButton = ($el) ->
  tracker = ->
    if PiwikAnalytics?
      PiwikAnalytics.trackEvent(railsController(), 'press', $el.text())
  $el.off 'click', tracker
  $el.on 'click', tracker

trackLink = ($el) ->
  tracker = ->
    if PiwikAnalytics?
      PiwikAnalytics.trackEvent(railsController(), 'click', $el.text())
  $el.off 'click', tracker
  $el.on 'click', tracker

trackSelect = ($el) ->
  tracker = ->
    if PiwikAnalytics?
      PiwikAnalytics.trackEvent(railsController(), 'select', $el.find(':selected').text())
  $el.off 'change', tracker
  $el.on 'change', tracker

$(document).on('turbolinks:load', prepareTracking)
