class PiwikAnalytics

  @load: ->
    # Piwik Analytics depends on a global _paq array. window is the global scope.
    window._paq = []
    window._paq.push(['setTrackerUrl', PiwikAnalytics.trackerUrl() + '/piwik.php'])
    window._paq.push(['setSiteId', PiwikAnalytics.siteId()])
    window._paq.push(['enableLinkTracking'])

    # Create a script element and insert it in the DOM
    pa = document.createElement("script")
    pa.type = "text/javascript"
    pa.defer = true
    pa.async = true
    pa.src =  PiwikAnalytics.trackerUrl() + '/piwik.js'
    firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore pa, firstScript

    # If Turbolinks is supported, set up a callback to track pageviews on page:change.
    # If it isn't supported, just track the pageview now.
    if typeof Turbolinks isnt 'undefined' and Turbolinks.supported
      document.addEventListener "turbolinks:load", (->
        PiwikAnalytics.trackPageView('refresh')
      ), true
    else
      PiwikAnalytics.trackPageView()

  @trackPageView: (url) ->
    unless PiwikAnalytics.isLocalRequest()
      if url
        if url == "refresh"
          window._paq.push ["setCustomUrl", document.location.href]
          window._paq.push ["setDocumentTitle", document.title]
          window._paq.push ["trackPageView"]
          #window._paq.push ["enableLinkTracking"]
        else
          window._paq.push ["trackPageView", url]
      else
        window._paq.push ["trackPageView"]

  @trackEcommerceView: (sku) ->
    @trackObject 'setEcommerceView', sku

  @trackGoal: (id, price=0 ) ->
    @trackObject 'trackGoal', id, price

  @trackEvent: (category, action, name, value=0 ) ->
    @trackObject 'trackEvent', category, action, name, value

  @trackLink: (url) ->
    @trackObject 'trackLink', src, 'link'

  @addEcommerceItem: (sku, name, category, price) ->
    @trackObject 'addEcommerceItem', sku, name, category, parseFloat(price)

  @trackEcommerceOrder: (order_id, revenue) ->
    @trackObject 'trackEcommerceOrder', order_id, parseFloat(revenue)

  @trackEcommerceCartUpdate: (sum) ->
    @trackObject 'trackEcommerceCartUpdate', parseFloat(sum)

  @trackObject: ->
    args = Array.prototype.slice.call(arguments)
    if PiwikAnalytics.isLocalRequest()
      console.log.apply(null, args)
    else
      window._paq.push(args)

  @isLocalRequest: ->
    if PiwikAnalytics.documentDomainIncludes "localhost"
      return true
    if PiwikAnalytics.documentDomainIncludes('127.0.0.1')
      return true
    if PiwikAnalytics.documentDomainIncludes('staging')
      return true

  @documentDomainIncludes: (str) ->
    document.domain.indexOf(str) isnt -1

  @status: ->
    window._paq

  @siteId: ->
    window.AK_TRACKING_MT_ID

  @trackerUrl: ->
    "https://stats.schueleraustausch.net"

export default PiwikAnalytics

