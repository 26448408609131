import $ from 'jquery'

initHelper = ->
  initPopovers()

initPopovers = ->
  if $('[data-toggle="popover"]').length > 0
    $('[data-toggle="popover"]').popover()

railsController = ->
  maybeController = ($("body").attr('class') || '')
    .split(' ')
    .filter((x) -> x.match(/-controller$/))
    .shift()
  (maybeController and maybeController.replace(/-controller/,'')) or 'unknown'

$(document).on('turbolinks:load', initHelper)

export default railsController
