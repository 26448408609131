# NOTE: we explicitly bring `$` into this scope to have
# slick attach its plugin (`$.fn.slick`) to it.
# Otherwise later calls to `$(...).slick` will use the
# global jQuery (which is unaltered) and fail...
import $ from 'jquery'
import 'slick-carousel'

initAllSliders = ->
  console.log("init all sliders")
  initSlider()
  initLandingSlider()

unslickSliders = ->
  $('#partner-logo-slider').slick('unslick')
  $('#footer-partner-logo-slider').slick('unslick')
  $('#landing-slider').slick('unslick')
  $('#awards-slider').slick('unslick')

initSlider = ->
  $('#awards-slider').slick(
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    variableWidth: true
  )
  $('#partner-logo-slider').slick(
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    speed: 750,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 8
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 10
        }
      }
    ]
  )
  $('#footer-partner-logo-slider').slick(
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'ease-in-out',
    speed: 750,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6
        }
      }
    ]
  )

initLandingSlider = ->
  $('#landing-slider').slick(
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    cssEase: "ease-in-out",
    speed: 1000
  )

$(document).on('turbolinks:load', initAllSliders)
$(document).on('turbolinks:before-cache', unslickSliders)
