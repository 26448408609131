class GoogleAnalytics

  @load: ->
    ((i, s, o, g, r, a, m) ->
      i["GoogleAnalyticsObject"] = r
      i[r] = i[r] or ->
        (i[r].q = i[r].q or []).push arguments

      i[r].l = 1 * new Date()

      a = s.createElement(o)
      m = s.getElementsByTagName(o)[0]

      a.async = 1
      a.src = g
      m.parentNode.insertBefore a, m
    ) window, document, "script", "https://www.google-analytics.com/analytics.js", "ga"

    if typeof ga isnt 'undefined'
      ga('create', GoogleAnalytics.analyticsId(), 'auto')
      ga('set', 'anonymizeIp', true)
      ga('send', 'pageview')
      ga('require', 'displayfeatures')
      ga('require', 'ecommerce', 'ecommerce.js')

    # If Turbolinks is supported, set up a callback to track pageviews on page:change.
    # If it isn't supported, just track the pageview now.
    if typeof Turbolinks isnt 'undefined' and Turbolinks.supported
      document.addEventListener "turbolinks:load", (->
        GoogleAnalytics.trackPageview()
      ), true
    else
      GoogleAnalytics.trackPageview()

  @trackPageview: (url) ->
    if typeof ga is undefined
      conole.log("missing ga in item")
    if GoogleAnalytics?
      unless GoogleAnalytics.isLocalRequest()
        if url
          ga('send','pageview', url)
        else
          ga('set', 'location', location.href.split('#')[0])
          ga('send','pageview')


  @addEcommerceItem: (tId, sku, name, category, price) ->
    if typeof ga is undefined
      conole.log("missing ga in item")
    if GoogleAnalytics?
      unless GoogleAnalytics.isLocalRequest()
        ga('ecommerce:addItem', {
          'id': tId,
          'name': name,
          'sku': sku,
          'category': category,
          'price':  price,
          'quantity': '1'
          })

  @trackEcommerceTransaction: (order_id, revenue) ->
    if typeof ga is undefined
      conole.log("missing ga in transaction")

    unless GoogleAnalytics.isLocalRequest()
      ga('ecommerce:addTransaction', {
        'id': order_id,
        'revenue': revenue,
        })

  @trackEcommerce: ->
    unless GoogleAnalytics.isLocalRequest()
      ga('ecommerce:send')

  @isLocalRequest: ->
    if GoogleAnalytics.documentDomainIncludes "localhost"
      return true
    if GoogleAnalytics.documentDomainIncludes('127.0.0.1')
      return true
    if GoogleAnalytics.documentDomainIncludes('staging')
      return true
    if GoogleAnalytics.documentDomainIncludes('devel')
      return true

  @documentDomainIncludes: (str) ->
    document.domain.indexOf(str) isnt -1

  @analyticsId: ->
    window.AK_TRACKING_GA_ID

export default GoogleAnalytics

